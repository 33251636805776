import Title from "../Title";
import Paragraph from "../Paragraph";

export default function AboutProfileReport() {
  return (
    <div>
      <Title text="How do I report a profile?" />
      <Paragraph>
        Reporting a profile is very similar to reporting an anonymous user.
        Navigate to the profile page, and in the "more options" section, you'll
        find a "report profile" button. Clicking on this button allows you to
        file a report, enabling users to bring attention to any concerns or
        issues related to the reported profile.
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
    </div>
  );
}
