import Title from "../Title";
import Paragraph from "../Paragraph";
import CustomColors from "../CustomColors";
import Grid from "@mui/material/Grid";
import Rate from "./assets/rate.png";

export default function AboutKarma() {
  return (
    <div>
      <Title text="What is karma score?" />
      <Paragraph>
        Your <span style={{ color: CustomColors.purple }}>karma score</span> is
        a reflection of the quality of content that you share on Schmick. Your
        karma goes <span style={{ color: "lightGreen" }}>up</span> or{" "}
        <span style={{ color: "red" }}>down</span> depending on your posts
        ratings from the community. Karma score is an incentive for posting fun
        content to the public.
      </Paragraph>
      <div style={{ padding: "10px" }}></div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <img src={Rate} alt=""></img>
        </Grid>
      </Grid>
    </div>
  );
}
