import axios from "axios";

export async function getProfiles() {
  const url =
    "https://us-central1-schmick-app.cloudfunctions.net/api_public/fetch-recommended-profiles-public";
  const res = await axios.get(url);
  const str = res.data.success.data.response;
  const json = JSON.parse(str);
  console.log(json.result);
  return json.result;
}
