import React from "react";

export default class WebConstraint extends React.Component {
  render() {
    const style = {
      maxWidth: this.props?.maxWidth ?? "1280px",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
    };

    return <div style={style}>{this.props.children}</div>;
  }
}
