import React from "react";
import WebConstraint from "../Components/WebConstraint.js";
import CustomColors from "../CustomColors.js";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DownloadButton } from "../Components/DownloadButton/DownloadButton.js";
import { MediaQuerySize } from "../util/MediaQuery.js";
import { Center } from "../Components/Center.js";
import FontFamily from "../Components/FontFamily.js";
import GradientText from "../Components/GradientText.js";
import HorProfileList from "../Components/HorProfileList/HorProfileList.js";
import ClipRRect from "../Components/ClipRRect.js";
import Box from "@mui/material/Box";

const delay = (val) => 300 + val;
const _mq = new MediaQuerySize(String(550 * 2) + "px");

export default class HeardSection extends React.Component {
  sectionHeight = () => "1080px";

  desc = (isCenter) => (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems={isCenter ? "center" : "flex-start"}
    >
      <Grid item>
        <BeHeardTitle isCenter={isCenter} />
      </Grid>
      <Grid item style={{ paddingTop: "30px" }}>
        <BeHeardInfo />
      </Grid>
      <Grid item style={{ paddingTop: "70px" }}>
        <DownloadButton text="I need it" />
      </Grid>
    </Grid>
  );

  posts = () => {
    const pad = {
      marginLeft: "10px",
      marginRight: "10px",
    };
    return (
      <div style={{ marginTop: "30px" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Box>
              <div data-aos="fade-left" data-aos-delay={delay(0)}>
                <GradientText
                  fontFamily={FontFamily.archivoBlack}
                  text="People are on Schmick"
                  colors={{
                    start: CustomColors.purple,
                    end: CustomColors.orange,
                  }}
                  size={20}
                  isSpan={true}
                />
              </div>
            </Box>
          </Grid>
          <Grid item>
            <div data-aos="fade-left" data-aos-delay={delay(500)}>
              <Typography
                style={{
                  color: CustomColors.white,
                  fontWeight: "bold",
                  fontFamily: FontFamily.archivoBlack,
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                <span>Get your handle before it's taken!</span>
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <Box
              sx={{
                height: "20px",
              }}
            ></Box>
            <ClipRRect borderRadius="20px">
              <HorProfileList />
            </ClipRRect>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item style={pad}>
                <a href="https://apps.apple.com/us/app/schmick-anonymous-nearby-chat/id1533537812?platform=iphone">
                  <img
                    width="200px"
                    src={"./appstore.svg"}
                    alt="Download iOS"
                  />
                </a>
              </Grid>
              <Grid item style={pad}>
                <a href="https://play.google.com/store/apps/details?id=app.schmick">
                  <img
                    width="220px"
                    src={"./google-play-badge.svg"}
                    alt="Download Android"
                  />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  desktop = () => (
    <div
      style={{
        minHeight: this.sectionHeight(),
        // backgroundColor: CustomColors.black,
        background: "linear-gradient(to bottom, rgb(10,10,30), black)",
      }}
    >
      <WebConstraint>
        <div
          style={{
            position: "relative",
            marginTop: "100px",
            marginBottom: "100px",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            style={{ minHeight: this.sectionHeight() }}
          >
            <Grid item>{this.desc()}</Grid>
            <Grid item>{this.posts()}</Grid>
          </Grid>
        </div>
      </WebConstraint>
    </div>
  );

  mobile = () => (
    <div
      style={{
        zIndex: 3,
        minHeight: this.sectionHeight(),
        backgroundColor: CustomColors.black,
      }}
    >
      <WebConstraint>
        <Grid container direction="column" justifyContent="center">
          <Grid item>
            <Center>
              <div style={{ paddingTop: "200px" }}>{this.desc(true)}</div>
            </Center>
          </Grid>
          <Grid item>
            <div style={{ paddingTop: "150px", paddingBottom: "150px" }}>
              {this.posts()}
            </div>
          </Grid>
        </Grid>
      </WebConstraint>
    </div>
  );

  render = () => _mq.build({ desktop: this.desktop(), mobile: this.mobile() });
}

class BeHeardInfo extends React.Component {
  render = () => (
    <div data-aos="fade" data-aos-delay={delay(600)}>
      <Typography
        style={{
          color: CustomColors.textLightGray,
          width: "400px",
          fontFamily: FontFamily.apple,
          fontSize: "25px",
          fontWeight: "bold",
        }}
      >
        {" "}
        Your voice matters, regardless of status. Here, attention comes
        naturally, ensuring you're always heard.
      </Typography>
    </div>
  );
}

class BeHeardTitle extends React.Component {
  style = (color) => ({
    color: color ?? CustomColors.white,
    fontWeight: "bold",
    fontFamily: FontFamily.archivoBlack,
    fontSize: "70px",
    textAlign: this.props.isCenter ? "center" : "left",
  });

  marg = () => ({ margin: "-1em 0px 0px" });

  content = () => (
    <Grid
      container
      direction="column"
      alignItems={this.props?.isCenter ? "center" : "flex-start"}
      justifyContent="center"
    >
      <Grid
        item
        style={this.marg()}
        data-aos="fade-right"
        data-aos-delay={delay(0)}
      >
        <Typography style={this.style()}>
          <span>You Belong.</span>
        </Typography>
      </Grid>

      <Grid
        item
        style={this.marg()}
        data-aos="fade-right"
        data-aos-delay={delay(300)}
      >
        <Box>
          <div>
            <GradientText
              animationDelay={delay(1000)}
              fontFamily={FontFamily.archivoBlack}
              text="Be Heard."
              colors={{
                start: CustomColors.purple,
                end: CustomColors.orange,
              }}
              size={70}
              isSpan={true}
            />
          </div>
        </Box>
      </Grid>
    </Grid>
  );

  render = () => <div>{this.content()}</div>;
}
