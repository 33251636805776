import Title from "../Title";
import Paragraph from "../Paragraph";

export default function AboutProfileSegmentation() {
  return (
    <div>
      <Title text="Will anonymous users be able to interact with profile-based content?" />
      <Paragraph>
        Anonymous users won't be able to interact with profile-based content.
        The content associated with anonymous users and profile-based content
        remains separate. To engage with profile-based content, it's necessary
        to create a profile, as interaction is restricted for those who remain
        anonymous.
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
    </div>
  );
}
