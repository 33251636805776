import React from "react";
import WebConstraint from "../Components/WebConstraint";
import GradientText from "../Components/GradientText";
import SchmickInboxDarkImage from "./assets/schmick-inbox-light-v2.png";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Parallax } from "react-scroll-parallax";
import Stack from "../Components/Stack/Stack";
import FadeShader from "../Components/ShadeFaderBottom/ShadeFaderBottom";
import { useIsMobile } from "../util/use-is-mobile";

const IntroSectionV2 = () => {
  const isMobile = useIsMobile();
  return (
    <FadeShader>
      <div
        style={{
          height: isMobile ? "1850px" : "1850px" /*"1620px"*/,
        }}
      >
        <WebConstraint>
          <Stack>
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <Parallax speed={-30}>
                  <Box sx={{ paddingLeft: "50px", paddingRight: "50px" }}>
                    <div data-aos="zoom-in-up" data-aos-duration="2000">
                      <GradientText
                        text="Connecting Communities with Ease and Authenticity."
                        colors={{ start: "#800080", end: "#FFA500" }}
                        size={"80px"}
                      />
                    </div>
                  </Box>
                </Parallax>
              </Grid>
            </Grid>

            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                {isMobile ? (
                  <Box sx={{ height: "50em" }} />
                ) : (
                  <Box sx={{ height: "30em" }} />
                )}
              </Grid>
              <Grid item>
                <div
                  data-aos="fade-up"
                  data-aos-duration="5000"
                  data-aos-delay="1000"
                >
                  <img
                    src={SchmickInboxDarkImage}
                    alt="Schmick Inbox"
                    style={{
                      maxWidth: "70%",
                      height: "auto",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Stack>
        </WebConstraint>
      </div>
    </FadeShader>
  );
};

export default IntroSectionV2;
