import Title from "../Title";
import Paragraph from "../Paragraph";

export default function AboutUserNotifications() {
  return (
    <div>
      <Title text="How do I control notifications?" />
      <Paragraph>
        To change your notification settings, navigate to the settings page
        where you will find can select the 'Manage Notification Options'
        setting.
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
    </div>
  );
}
