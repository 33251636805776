import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CustomColors from "../../CustomColors";
import EllipsisText from "react-ellipsis-text";
import { stringToTitleCase } from "../../util/string-to-title-case";

const ProfileTile = ({ username, name, avatarUrl }) => {
  return (
    <Card
      // No padding in the card
      style={{
        padding: "0px",
        margin: "0px",

        width: 150,
        height: 200,
      }}
      sx={{
        borderRadius: "20px",
        backgroundColor: "rgba(30, 30, 45, 0.5)",
      }}
    >
      <CardContent sx={{ textAlign: "center" }}>
        <Box height="15px" />
        <Avatar
          alt={name ?? "Name"}
          src={avatarUrl ?? "https://www.w3schools.com/howto/img_avatar.png"}
          sx={{ width: 85, height: 85, margin: "0 auto" }}
        />
        <Box height="15px" />
        <div>
          <EllipsisText
            length="12"
            text={"@" + username ?? "username"}
            style={{
              fontSize: "16px",
              color: CustomColors.white,
            }}
          />
        </div>
        <EllipsisText
          length="10"
          text={stringToTitleCase(name) ?? "Name"}
          style={{
            fontSize: "12px",
            color: CustomColors.textLightGray,
          }}
        />
      </CardContent>
    </Card>
  );
};

export default ProfileTile;
