import { useMediaQuery } from "react-responsive";
import { MediaQueryType } from "./MediaQuery";

export const useIsMobile = () => {
  const width = "915px";
  const isMobile = useMediaQuery({
    query: width ? `(max-width: ${width})` : MediaQueryType.isMobile,
  });
  return isMobile;
};
