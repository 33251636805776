import Title from "../Title";
import Paragraph from "../Paragraph";
import CustomColors from "../CustomColors";
import Grid from "@mui/material/Grid";
import Chat from "./assets/chat.png";
import Please from "./assets/please.png";

export default function AboutSchmick(props) {
  return (
    <div>
      <Title text="What is Schmick?" />
      <Paragraph>
        <span style={{ color: CustomColors.purple }}>Schmick</span> is a social
        platform where you can create posts anonymously. Each serves as a{" "}
        <span style={{ color: "lightGreen" }}>chat room</span> of its own. To
        allow for <span style={{ color: CustomColors.orange }}>anonymity</span>,
        for every post you make or participate in, it creates a{" "}
        <span style={{ color: "red" }}>new</span> generated{" "}
        <span style={{ color: CustomColors.purple }}>unique username</span>.
      </Paragraph>

      <Title text="Help us!" />
      <Paragraph>
        <span style={{ color: CustomColors.purple }}>Share</span> Schmick and
        help us grow. Be respectful to one and another.{" "}
        <span style={{ color: "red" }}>Illegal</span> activities,{" "}
        <span style={{ color: "red" }}>impersonation</span>, and{" "}
        <span style={{ color: "red" }}>harassment</span> are not allowed. When
        users are not following guidelines,{" "}
        <span style={{ color: CustomColors.purple }}>help Schmick</span> by
        downvoting or reporting posts and chats. We trust you as a community to
        do the right thing. Now let's get Schmicking!
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item>
          <img
            src={Chat}
            alt=""
            style={{ weight: "200%", height: "200%" }}
          ></img>
        </Grid>
        <Grid item>
          <img src={Please} alt=""></img>
        </Grid>
      </Grid>
    </div>
  );
}
