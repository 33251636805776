import Title from "../Title";
import Paragraph from "../Paragraph";

export default function AboutProfilePostPrivacyToggle() {
  return (
    <div>
      <Title text="How do I make a public profile post private?" />
      <Paragraph>
        You can do that by going to the top right corner of the chat page, click
        on the vertical dots for more options, and choose "set as private".
        Another convenient method is to click on the lock/unlock icon situated
        above the chat page. These options offer flexibility in ensuring your
        profile's privacy according to your preferences.
      </Paragraph>
    </div>
  );
}
