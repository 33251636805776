import Title from "../Title";
import Paragraph from "../Paragraph";

export default function AboutSchmickEmails() {
  return (
    <div>
      <Title text="What should I do if Schmick isn't sending me emails?" />
      <Paragraph>
        If you are not receiving emails from Schmick, particularly verification
        or forgot password emails, follow these steps:
        <div style={{ padding: "30px" }}></div>
        <ul>
          <li>
            Check your inbox and junk/spam folder to see if you received the
            email.
          </li>
          <li>
            If it's not there, your email service provider may be filtering out
            Schmick's emails.
          </li>
          <li>
            In this case, consider using another email service provider,
            preferably ones ending with @gmail.com or @outlook.com, to ensure
            you receive the necessary emails from Schmick.
          </li>
          <li>
            If you have an existing account then update your email by going to
            your account settings (on the menu drawer) and selecting the 'Change
            Email and Password' option and change to a preferable email domain.
          </li>
          <li>
            Otherwise, attempt to create a new account using an email from one
            of the providers mentioned.
          </li>
        </ul>
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
    </div>
  );
}
