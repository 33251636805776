import Title from "../Title";
import Paragraph from "../Paragraph";
import CustomColors from "../CustomColors";
import Grid from "@mui/material/Grid";
import Stats from "./assets/stats.png";

export default function AboutPosts() {
  return (
    <div>
      <Title text="How do posts work?" />
      <Paragraph>
        Your created <span style={{ color: CustomColors.orange }}>posts</span>{" "}
        and <span style={{ color: CustomColors.orange }}>chats</span> name will
        be displayed in{" "}
        <span style={{ color: CustomColors.purple }}>purple</span> and will
        generate a{" "}
        <span style={{ color: CustomColors.purple }}>unique identifier</span>{" "}
        upon posting or messaging. Schmick{" "}
        <span style={{ color: "red" }}>removes</span> posts that score below{" "}
        <span style={{ color: "red" }}>-50</span> and chat messages that score
        below <span style={{ color: "red" }}>-30</span>. You can only see public
        posts within{" "}
        <span style={{ color: CustomColors.orange }}>seven days</span>. If your
        post is the highest rating in the city, your post will be the Schmick of
        the day!
      </Paragraph>

      <div style={{ padding: "30px" }}></div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <img src={Stats} alt=""></img>
        </Grid>
      </Grid>
    </div>
  );
}
