import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import ReactNavbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "aos/dist/aos.css";
import "./NavBar.css";

import WebConstraint from "../WebConstraint";
import SchmickLogo from "../../SchmickLogo";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { ShowOn } from "../../util/MediaQuery";
import NavButton from "../NavButton/NavButton";
import Box from "@mui/material/Box";
import CustomColors from "../../CustomColors";
import { useIsMobile } from "../../util/use-is-mobile";
import { useNavigate } from "react-router-dom";
import { useParallaxController } from "react-scroll-parallax";

const delay = (val) => String(0 + val);

const MobileMenu = ({ onClick }) => {
  const style = {
    fontSize: "2rem",
    marginTop: "50px",
    marginBottom: "50px",
  };

  return (
    <Box
      sx={{
        backgroundColor: CustomColors.superDarkNavy2,
        position: "absolute",
        top: "100%",
        width: "100%",
        left: "0",
        right: "0",
      }}
    >
      <NavButton style={style} onClick={onClick} label="Home" />
      <NavButton style={style} onClick={onClick} label="Support" />
      <NavButton style={style} onClick={onClick} label="Guidelines" />
    </Box>
  );
};

const NavBar = ({ onPageClick }) => {
  let [page, setPage] = useState("Schmick");
  let [isMobileOptionsOpen, setIsMobileOptionsOpen] = useState(false);
  const navigate = useNavigate();
  const parallaxController = useParallaxController();

  const navigateHomeAndRefresh = () => {
    window.scrollTo(0, 0);
    parallaxController.update();

    // delay promise
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    delay(300).then(() => {
      navigate("/");
      window.scrollTo(0, 0);
      parallaxController.update();
    });
  };

  const handleClick = (val) => {
    page = val;
    if (val === "/Schmick" || val === "/Home" || val === "/") {
      navigateHomeAndRefresh();
      return;
    }
    setPage(page);
    onPageClick(val);
    console.log("'" + val?.toString() + "' is clicked.");
  };

  const Dropdown = () => (
    // Padding to the right
    <Box
      sx={{
        paddingRight: "10px",
      }}
    >
      {isMobileOptionsOpen ? (
        <CloseIcon
          data-aos="zoom-in"
          data-aos-duration="100"
          style={{
            color: "white",
            fontSize: "3rem",
            cursor: "pointer",
          }}
          onClick={() => setIsMobileOptionsOpen(!isMobileOptionsOpen)}
        />
      ) : (
        <MenuIcon
          data-aos="zoom-in"
          data-aos-duration="100"
          style={{
            color: "white",
            fontSize: "3rem",
            cursor: "pointer",
          }}
          onClick={() => setIsMobileOptionsOpen(!isMobileOptionsOpen)}
        />
      )}
    </Box>
  );

  const Buttons = () => {
    const style = {
      marginLeft: "5px",
      marginRight: "5px",
    };

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <div data-aos="fade-left" data-aos-delay={delay(900)}>
          <NavButton style={style} onClick={handleClick} label="Home" />
        </div>
        <div data-aos="fade-left" data-aos-delay={delay(600)}>
          <NavButton style={style} onClick={handleClick} label="Support" />
        </div>
        <div data-aos="fade-left" data-aos-delay={delay(300)}>
          <NavButton style={style} onClick={handleClick} label="Guidelines" />
        </div>
      </Grid>
    );
  };

  const isMobile = useIsMobile();

  return (
    <ReactNavbar
      className={isMobile ? "" : "blurred-background"}
      fixed="top"
      style={{
        ...{
          zIndex: 9000,
          paddingTop: "5px",
          paddingBottom: "5px",
        },
        ...(isMobile
          ? {
              backgroundColor: CustomColors.superDarkNavy2,
            }
          : {}),
      }}
    >
      <WebConstraint>
        <Nav style={{ height: "100%" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <div data-aos="fade-right" data-aos-delay="200">
                <SchmickLogo onClick={handleClick} />
              </div>
            </Grid>
            <Grid item>
              <ShowOn mobile={<Dropdown />} desktop={<Buttons />} />
            </Grid>
          </Grid>
        </Nav>
      </WebConstraint>

      {isMobileOptionsOpen && <MobileMenu onClick={handleClick} />}
    </ReactNavbar>
  );
};

export default NavBar;
