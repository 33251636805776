import Title from "../Title";
import Paragraph from "../Paragraph";

export default function AboutProfileHomeFeed() {
  return (
    <div>
      <Title text="How can I see my friends profile posts on my home feed?" />
      <Paragraph>
        To view the profile posts of the people you follow on your home feed,
        it's essential to ensure that you are following them. Only by following
        individuals will their posts appear on your feed, allowing you to stay
        updated with the content shared by those you choose to follow.
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
    </div>
  );
}
