// ClipRRect.js
import React from "react";

const ClipRRect = ({ borderRadius, children, style }) => {
  const combinedStyle = {
    borderRadius: borderRadius,
    overflow: "hidden",
    ...style,
  };

  return <div style={combinedStyle}>{children}</div>;
};

export default ClipRRect;
