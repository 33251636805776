import React from "react";
import CustomColors from "./CustomColors";

export default class Paragraph extends React.Component {
  render() {
    return (
      <div
        style={{
          color: CustomColors.paragraphColor,
          fontSize: "26px",
          fontWeight: "500",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
