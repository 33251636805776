import React from "react";

const ShadeFader = ({
  children,
  fadeStartColor = "rgba(255, 255, 255, 0)",
  fadeEndColor = "black",
  overflow = "visible",
}) => {
  const containerStyle = {
    position: "relative",
    overflow: overflow, // Ensures the fade doesn't extend outside
  };

  const fadeStyle = {
    position: "absolute",
    height: "100px",
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1, // Ensures the fade is above the content
    background: `linear-gradient(to bottom, ${fadeStartColor}, ${fadeEndColor})`,
  };

  return (
    <div style={containerStyle}>
      <div style={fadeStyle} />
      <div style={{ position: "relative", zIndex: 0 }}>{children}</div>
    </div>
  );
};

export default ShadeFader;
