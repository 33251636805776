import React from "react";
import Grid from "@mui/material/Grid";

export class Center extends React.Component {
  render = () => (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item>{this.props.children}</Grid>
    </Grid>
  );
}
