import Grid from "@mui/material/Grid";

import Title from "../Title";
import Paragraph from "../Paragraph";
import CustomColors from "../CustomColors";
import BlockPosts from "./assets/BlockPosts.mp4";
import UnblockPosts from "./assets/UnblockPosts.mp4";

export default function AboutBlockPost() {
  return (
    <div>
      <Title text="How can I block posts from my feed?" />
      <Paragraph>
        Once you choose to block a user's posts, they are gone and will no
        longer be shown on your feed. You can block a user’s posts by:
        <ol>
          <li>
            Tapping on a{" "}
            <span style={{ color: CustomColors.purple }}>post</span>.
          </li>
          <li>
            Selecting the{" "}
            <span style={{ color: CustomColors.purple }}>
              (three vertical dots)
            </span>{" "}
            button for more options.
          </li>
          <li>
            Selecting{" "}
            <span style={{ color: CustomColors.purple }}>‘Block’</span>.
          </li>
          <li>
            Pressing the{" "}
            <span style={{ color: CustomColors.purple }}>‘Hold me Here’</span>{" "}
            button to block.
          </li>
        </ol>
      </Paragraph>

      <div style={{ padding: "30px" }}></div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <video autoPlay loop>
            <source src={BlockPosts} type="video/mp4"></source>
          </video>
        </Grid>
      </Grid>
      <div style={{ padding: "30px" }}></div>

      <Paragraph>
        If you would like to undo your blocks. You can:
        <ol>
          <li>
            Go to the menu drawer by clicking{" "}
            <span style={{ color: CustomColors.purple }}>(hamburger icon)</span>{" "}
            on the top left corner of the home page.
          </li>
          <li>
            Click <span style={{ color: CustomColors.purple }}>‘Settings’</span>{" "}
            to navigate to the settings page.
          </li>
          <li>
            Select{" "}
            <span style={{ color: CustomColors.purple }}>‘Blocklist’</span> to
            view all block contents.
          </li>
          <li>
            Click <span style={{ color: CustomColors.purple }}>‘Unblock’</span>{" "}
            to unblock them.
          </li>
        </ol>
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <video autoPlay loop>
            <source src={UnblockPosts} type="video/mp4"></source>
          </video>
        </Grid>
      </Grid>
    </div>
  );
}
