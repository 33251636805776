import Title from "../Title";
import Paragraph from "../Paragraph";

export default function AboutProfile() {
  return (
    <div>
      <Title text="What is a profile wall?" />
      <Paragraph>
        A profile wall encompasses all the posts and images associated with your
        profile. Within this space, you have the ability to curate the content
        displayed, including profile posts and images.
      </Paragraph>
      <Title text="How do I make a profile?" />
      <Paragraph>
        If you don't have a profile, you can create a profile by tapping the
        profile avatar, which will guide you through the profile creation
        process.
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
    </div>
  );
}
