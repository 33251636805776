import Title from "../Title";
import Paragraph from "../Paragraph";

export default function AboutProfilePostPrivacy() {
  return (
    <div>
      <Title text="What is the difference between public and private profile posts?" />
      <Paragraph>
        The distinction between public and private profile posts lies in their
        accessibility and interaction. Public profile posts are viewable and
        open for interaction to anyone, excluding those who have been blocked.
        On the other hand, private profile posts are exclusively accessible to
        specific profiles as determined by the post creator. Users allowed or
        invited into the chat can interact with these private posts. To include
        others in the private chat, users can tag them, with the caveat that
        tagging is limited to one's followers and the post creator has enabled
        tagging. It's important to note that when transitioning from a public to
        a private chat, users who were initially part of the public chat will
        seamlessly remain in the private chat.
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
    </div>
  );
}
