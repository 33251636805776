import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import NavBar from "./Components/NavBar/NavBar";
import LandingPage from "./LandingPage/LandingPage";
import CommunityGuidelines from "./CommunityGuidelines";
import SupportPage from "./SupportPage";
import AOS from "aos";

const App = () => {
  let [pageName, setPageName] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="App">
      <NavBar
        onPageClick={(val) => {
          pageName = val;
          setPageName(pageName);
          console.log("On page: ", pageName);
        }}
      />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/Guidelines" element={<CommunityGuidelines />} />
        <Route path="/Home" element={<LandingPage />} />
        <Route path="/Support" element={<SupportPage />} />
      </Routes>
    </div>
  );
};

export default App;
