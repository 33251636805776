import React from "react";
import Paragraph from "./Paragraph";
import Title from "./Title";
import WebConstraint from "./Components/WebConstraint";
import Footer from "./Footer";
import { ShowOn } from "./util/MediaQuery";

import Grid from "@mui/material/Grid";

export default class CommunityGuidelines extends React.Component {
  render() {
    console.log("Guidelines is rendered");
    return (
      <Grid>
        <WebConstraint>
          <div
            style={{
              margin: "150px 30px 150px",
            }}
          >
            <Title text="Community Guidelines" style={{ color: "purple" }} />
            <Paragraph>
              Schmick’s purpose is to create a fun and safe community where
              people can connect and interact freely. Harassment, violence or
              any other malicious actions are forbidden. All content that does
              not follow the guidelines or disrupts the experience of Schmick
              service will be deleted. Furthermore, the user may be subjected to
              suspension from Schmick. Our rules may appear to be more strict
              than those of other social media platforms. This is to ensure the
              safety and enjoyment of our users.
            </Paragraph>
            <Title text="Respect" />
            <Paragraph>
              Respect is vital in maintaining a welcoming community on Schmick.
              We believe that respecting others is critical since it gives
              community members the confidence to express themselves and fully
              engage in our community.
              <ul>
                <li>Don’t send or ask for nudes or other sexual content.</li>
                <li>Do NOT post or send thirsty, creepy or shady content.</li>
                <li>
                  Don’t share content that is sexist, racist, homophobic or
                  discriminatory in any way.
                </li>
                <li>
                  {" "}
                  It’s NOT ok to use pictures or videos of people without
                  seeking their permission.
                </li>
                <li>Never threaten or intimidate other users. </li>
              </ul>
            </Paragraph>
            <Title text="Identity" />
            <Paragraph>
              The safety of our users is our top priority. Schmick is an
              anonymous public platform. Sharing your identity can lead to
              malicious targeting. We strongly recommend not to share any
              personally identifiable information of any kind and help keep
              things anonymous.
              <ul>
                <li>Prevent sharing and asking age or gender. </li>
                <li>Prevent sharing your or particular names. </li>
                <li>Do not disclose your exact location. </li>
              </ul>
            </Paragraph>
            <Title text="Impersonation" />
            <Paragraph>
              At Schmick, we are committed to maintaining a trustworthy and safe
              community. Impersonation, or pretending to be someone else to
              deceive, is strictly prohibited and will not be tolerated.
            </Paragraph>
            <Paragraph>
              1. Prohibited actions:
              <ul>
                <li>
                  Creating a profile under another person's name or likeness
                  without their consent.{" "}
                </li>
                <li>
                  Misleading other users by impersonating as public figures,
                  brands, or any other entities.
                </li>
              </ul>
            </Paragraph>
            <Paragraph>
              2. Verification and Enforcement:
              <ul>
                <li>
                  Users may be required to verify their identity to prevent
                  impersonation.
                </li>
                <li>
                  Any account found engaging in impersonation will be subject to
                  immediate suspension or termination.
                </li>
              </ul>
            </Paragraph>
            <Paragraph>
              3. Cooperation with Lawful Enforcement:
              <ul>
                <li>
                  In severe cases involving fraud or other legal violations, we
                  will cooperate with law enforcement to address the issue
                  effectively.
                </li>
              </ul>
            </Paragraph>

            <Title text="Spam" />
            <Paragraph>
              Schmick generally defines spam as repeated, unwanted, unsolicited
              actions or any that may negatively impact our user experience.
              Spam on Schmick is a violation of the Terms of Use. This action is
              punishable by content removal and account suspension or permanent
              ban. If you are unsure whether your content is spam or not,
              contact our moderation team. In general, try posting authentic
              content and stay in context with the topics discussed under each
              post.
            </Paragraph>
            <Title text="Safety" />
            <Paragraph>
              Schmick strongly believes that the following guidelines can help
              create and encourage a safer and more friendly experience.
              <ul>
                <li>
                  Do not post content that is sexually explicit or involves
                  sexual violence or assault.{" "}
                </li>
                <li>
                  Do not post content that is gory or has graphic injuries.{" "}
                </li>
                <li>
                  Do not post content that promotes violence, such as violent
                  acts, guns, knives or other weapons.{" "}
                </li>
                <li>Do not promote drugs, drug accessories or equipment. </li>
              </ul>
            </Paragraph>
            <Title text="Advertisement and Scams" />
            <Paragraph>
              Schmick is not a marketplace. To protect our users from scams, we
              do not allow any advertisement of products, services, scams, or
              malicious content.
              <ul>
                <li>Do not ask others to send money.</li>
                <li>
                  Advertising your PayPal, cash-app other payment service links
                  are not allowed.{" "}
                </li>
                <li>
                  Advertisement, services, scams or malicious conduct are not
                  allowed.{" "}
                </li>
                <li>
                  Do not promote or sell illegal goods. This includes illegal
                  drugs or activities.
                </li>
                <li>
                  Prevent promoting social media accounts from Snapchat, Kik,
                  and etc.{" "}
                </li>
              </ul>
            </Paragraph>
            <Title text="Self-injury and Suicide" />
            <Paragraph>
              At Schmick, we recognize that people in their daily lives are
              facing and struggling with hardships. We aim to give access and
              allow people to share these challenges and receive support when
              they need it. You may not promote or encourage suicide or
              self-harm. Violations of these terms include but are not limited
              to the following.
              <ul>
                <li>
                  Do not encourage someone to physically harm or kill
                  themselves.
                </li>
                <li>
                  Do not ask others for encouragement to engage in suicide or
                  self-harm.
                </li>
                <li>
                  Do not participate or share information on methods,
                  strategies, directions that would engage and assists people in
                  suicide or self-harm.
                </li>
              </ul>
            </Paragraph>
            <Title text="Keep it Lawful" />
            <Paragraph>
              Do not use Schmick for any illegal activities, including but not
              limited to the following.
              <ul>
                <li>
                  Do not sell illegal goods or services such as drugs, guns, and
                  etc.
                </li>
                <li>Do not promote or advertise sexual services.</li>
                <li>Do not try to access other user’s accounts.</li>
                <li>
                  Do not attempt to damage or interfere with Schmick’s services.
                </li>
                <li>
                  Do not make hateful content or promote violence or organized
                  crime of any kind, including terrorism.
                </li>
              </ul>
            </Paragraph>
            <Title text="Ban Evasion" />
            <Paragraph>
              Trying to get around or bypass suspensions, bans or locked
              accounts by any means is not allowed. Any attempt could result in
              longer suspension and even a permanent ban.
            </Paragraph>
            <Title text="Enforcement" />
            <Paragraph>
              We take our community guidelines seriously. The actions we take
              against content that does not follow these guidelines will depend
              on the severity of rules broken. We understand context matters.
              Therefore, we will carefully investigate each case to determine
              the correct action required to solve the issue.
            </Paragraph>
            <Title text="Help Us" />
            <Paragraph>
              You can help us by down-rating and reporting any users that do not
              respect our community guidelines. Our moderation team will
              investigate the report and take necessary actions. You can learn
              more about how to report users by going{" "}
              <a href="./Support/AboutReportUser.js"> here.</a>
            </Paragraph>
          </div>
        </WebConstraint>
        <ShowOn desktop={<Footer />} />
        <ShowOn mobile={<Footer />} />
      </Grid>
    );
  }
}
