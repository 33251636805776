import Title from "../Title";
import Paragraph from "../Paragraph";

export default function AboutProfileDm() {
  return (
    <div>
      <Title text="How can I directly message a profile or multiple profiles?" />
      <Paragraph>
        While, we do not have conventional direct messages, you still privately
        message by performing the following:
        <div style={{ padding: "30px" }}></div>
        <ul>
          <li>Go to post creation</li>
          <li>Select the 'Post as @{"profile handle"} option'</li>
          <li>Turn the 'Set your post as private' toggle on</li>
          <li>
            Tag your followers in the post message by using the '@' symbol
            followed by their username to include them in the private chat
          </li>
          <li>Create the post</li>
          <li>
            Additionally, you can add more people by tagging them in the chat
          </li>
        </ul>
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
    </div>
  );
}
