import React, { useState } from "react";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import CustomColors from "../../CustomColors";
import FontFamily from "../FontFamily";

const NavButton = ({
  onClick,
  href,
  label,
  isWhite,
  textColor,
  backgroundColor,
  style,
}) => {
  const [state, setState] = useState({
    isWhite: isWhite ?? false,
    bkgColor: backgroundColor ?? "transparent",
    txtColor: textColor ?? CustomColors.white,
  });

  const onHover = () => {
    console.log("Mouse enter");
    setState({
      txtColor: state.isWhite
        ? CustomColors.superDarkNavy
        : CustomColors.orange,
      bkgColor: state.isWhite ? CustomColors.orange : "transparent",
    });
  };

  const onLeave = () => {
    console.log("Mouse leave");
    setState({
      txtColor: state.isWhite ? CustomColors.superDarkNavy : CustomColors.white,
      bkgColor: state.isWhite ? CustomColors.white : "transparent",
    });
  };

  let defaultStyle = {
    fontFamily: FontFamily.apple,
    fontWeight: "bold",
    align: "middle",
    textAlign: "center",
    borderRadius: "10px",
    color: state.txtColor,
    backgroundColor: state.bkgColor,
    paddingLeft: "15px",
    paddingRight: "15px",
    marginLeft: "15px",
    marginRight: "15px",
    paddingTop: "3px",
    paddingBottom: "3px",
    fontSize: "16px",
  };

  if (style !== undefined && style !== null) {
    defaultStyle = { ...defaultStyle, ...style };
  }

  const button = () => (
    <div className={"hvr-grow"}>
      <Nav.Link
        onClick={() => {
          onClick?.("/" + label);
          window.scrollTo(0, 0);
        }}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        href={href ?? "/" + label}
        style={defaultStyle}
      >
        {label}
      </Nav.Link>
    </div>
  );

  return href === undefined ? (
    <Link to={"/" + (label ?? "")}>{button()}</Link>
  ) : (
    <>{button()}</>
  );
};

NavButton.White = function ({ onClick, href, label }) {
  return (
    <NavButton
      onClick={onClick}
      href={href}
      isWhite={true}
      label={label}
      textColor={CustomColors.superDarkNavy}
      backgroundColor={CustomColors.white}
    />
  );
};

export default NavButton;
