import React, { useState } from "react";
import CustomColors from "./CustomColors";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

const SchmickLogo = (props) => {
  const [isHover, setIsHover] = useState(false);

  const onHover = () => {
    setIsHover(true);
  };

  const onLeave = () => {
    setIsHover(false);
  };

  const style = {
    fontSize: "30px",
    color: isHover ? CustomColors.orange : CustomColors.white,
    paddingLeft: "15px",
    paddingRight: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginTop: "0px",
    marginBottom: "0px",
    fontFamily: "Pacifico",
  };

  return (
    <Link to="/">
      <Navbar.Brand
        onClick={() => props.onClick("/Schmick")}
        className={"hvr-grow"}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        href="#Schmick"
        style={style}
      >
        Schmick
      </Navbar.Brand>
    </Link>
  );
};

export default SchmickLogo;
