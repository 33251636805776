// material ui
import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";

// Other lib.
import "aos/dist/aos.css";
import "./LandingPage.css";
import SocialSection from "./SocialSection";
import Footer from "../Footer";
import IntroSectionV2 from "./IntroSectionV2";
import AboutSection from "./AboutSection";
import HeardSection from "./HeardSection";

const LandingPage = () => {
  useEffect(() => {
    document.body.classList.add("black-background");
    document.body.classList.remove("white-background");

    return () => {
      document.body.classList.remove("black-background");
    };
  }, []);

  return (
    <Grid container direction="column">
      <IntroSectionV2 />
      <AboutSection />
      <SocialSection />
      <HeardSection />
      <Footer />
    </Grid>
  );
};

export default LandingPage;
