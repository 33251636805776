import Title from "../Title";
import Paragraph from "../Paragraph";
import CustomColors from "../CustomColors";
import Grid from "@mui/material/Grid";
import SignIn from "./assets/SignIn.mp4";
export default function AboutLogin() {
  return (
    <div>
      <Title text="How do I sign in or sign up?" />
      <Paragraph>
        The process for sign in and sign up is done in the same process. Upon
        first launch of the application you can sign in or sign up by:
        <ol>
          <li>
            Tapping{" "}
            <span style={{ color: CustomColors.purple }}>‘Continue’</span> from
            the landing page.
          </li>
          <li>
            Enter your phone number in the field then tap{" "}
            <span style={{ color: CustomColors.purple }}>‘Send code’</span> and
            it will direct you to the next page. We will send you an SMS
            verification code.
          </li>
          <li>
            Once you received a verification code. Enter the code and tap{" "}
            <span style={{ color: CustomColors.purple }}>‘Verify’</span> to sign
            in or sign up.
          </li>
          <li>
            Tap <span style={{ color: CustomColors.purple }}>‘Enable’</span> to
            enable location. Schmick is based on feeds that are near you and are
            required to have device location permission.
          </li>
          <li>
            Once the location is enabled, tap{" "}
            <span style={{ color: CustomColors.purple }}>‘Continue’</span> to
            proceed to the Schmick home screen.
          </li>
        </ol>
      </Paragraph>
      <div style={{ padding: "40px" }}></div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <video autoPlay loop>
            <source src={SignIn} type="video/mp4"></source>
          </video>
        </Grid>
      </Grid>
    </div>
  );
}
