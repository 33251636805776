import Title from "../Title";
import Paragraph from "../Paragraph";
import CustomColors from "../CustomColors";
import Grid from "@mui/material/Grid";
import ReportPost from "./assets/ReportPost.mp4";
import ReportComment from "./assets/ReportComment.mp4";

export default function AboutReportUser() {
  return (
    <div>
      <Title text="How can I report a user?" />
      <Paragraph>
        If you need to report a user, there are two ways of letting Schmick
        know. You can report a:
        <ol>
          <li>
            Post by tapping on the more options button{" "}
            <span style={{ color: CustomColors.purple }}>
              (vertical three dots)
            </span>{" "}
            and selecting{" "}
            <span style={{ color: CustomColors.purple }}>‘Flag Content’</span>.
          </li>
          <li>
            Chat by holding down a chat bubble and selecting{" "}
            <span style={{ color: CustomColors.purple }}>‘Report’</span> on the
            menu.{" "}
          </li>
        </ol>
        Once you are on the report page, select the category of the report you
        are submitting and provide Schmick with a detailed description of the
        situation.
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item>
          <video autoPlay loop>
            <source src={ReportPost} type="video/mp4"></source>
          </video>
        </Grid>
        <Grid item>
          <video autoPlay loop>
            <source src={ReportComment} type="video/mp4"></source>
          </video>
        </Grid>
      </Grid>
    </div>
  );
}
