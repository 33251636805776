// Material UI
import "aos/dist/aos.css";
import React from "react";
import CustomColors from "../CustomColors";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import { Card, CardBody } from "@nextui-org/react";

import WebConstraint from "../Components/WebConstraint";
import schmickChatDarkV2ImgSrc from "./assets/schmick-chat-dark-v2.png";
import schmickHomeDarkV2ImgSrc from "./assets/schmick-home-dark-v2.png";
import schmickActivityDarkV2ImgSrc from "./assets/schmick-activity-dark-v2.png";

import { DownloadButton } from "../Components/DownloadButton/DownloadButton";
import FadeSlideShow from "../Components/FadeSlideShow/FadeSlideShow";
import { MediaQuerySize } from "../util/MediaQuery";
import GradientText from "../Components/GradientText";
import FontFamily from "../Components/FontFamily";
import { Parallax } from "react-scroll-parallax";

const delay = (val) => 300 + val;

const _mq = new MediaQuerySize(String(570 * 2) + "px");

export default class AboutSection extends React.Component {
  sectionHeight = () => "1080px";

  about = () => (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      {/* Information */}
      {/* <Card isBlurred shadow="sm">
        <CardBody> */}
      <Grid item>
        <GetAttentionTitle />
      </Grid>

      {/* <Grid item>
        <ClipRRect>
          <img
            alt="Schmick Post"
            src={schmickPostDarkImgSrc}
            style={{
              height: "60%",
              width: "60%",
              alignItems: "center",
              justifyContent: "center",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          ></img>
        </ClipRRect>
      </Grid> */}

      <Grid item>
        <AboutAppDesc />
      </Grid>
      {/* </CardBody>
      </Card> */}

      {/* Download button */}
      <Grid item>
        <div
          style={{
            paddingTop: "50px",
            // marginLeft: "70px",
          }}
        >
          <DownloadButton />
        </div>
      </Grid>
    </Grid>
  );

  desktop = () => (
    <div
      style={{
        zIndex: 4,
        // background: "linear-gradient(to bottom, #000000, #15002e)",
        background: "linear-gradient(to bottom, #000000, rgb(10,10,30))",
        height: this.sectionHeight(),
      }}
    >
      <WebConstraint>
        <div style={{ position: "relative" }}>
          <div
            style={{ position: "absolute", right: "100px", bottom: "-150px" }}
          >
            <div>
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    top: "-900px",
                    right: "0px",
                  }}
                >
                  <SchmickChatImg />
                </div>
              </div>
            </div>
          </div>
          <Grid
            style={{ height: this.sectionHeight() }}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={6}>
              {this.about()}
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column" justifyContent="center">
                <Grid item style={{ marginBottom: "100px" }}>
                  <div style={{ position: "relative", top: "-230px" }}>
                    <PeopleTalkDesc />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </WebConstraint>
    </div>
  );

  mobile = () => (
    <div
      style={{
        zIndex: 4,
        backgroundColor: CustomColors.superDarkNavy,
        height: "2000px",
      }}
    >
      <WebConstraint>
        <Grid container direction="column">
          <Grid item>
            <Grid container direction="row" justifyContent="center">
              <div style={{ paddingTop: "100px", maxWidth: "800px" }}>
                {this.about()}
              </div>
            </Grid>
          </Grid>
          <Grid item>
            <div style={{ paddingTop: "125px", paddingBottom: "50px" }}>
              <PeopleTalkDesc />
            </div>
          </Grid>
          <Grid item>
            <div style={{ paddingTop: "25px" }}>
              <SchmickChatImg />
            </div>
          </Grid>
        </Grid>
      </WebConstraint>
    </div>
  );

  render = () =>
    _mq.build({ desktop: this.desktop(), mobile: this.mobile(true) });
}

class SchmickChatImg extends React.Component {
  render = () => (
    <Parallax speed={30}>
      <div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <FadeSlideShow
              images={[
                schmickHomeDarkV2ImgSrc,
                schmickChatDarkV2ImgSrc,
                schmickActivityDarkV2ImgSrc,
              ]}
            />
          </Grid>
        </Grid>
      </div>
    </Parallax>
  );
}

class PeopleTalkDesc extends React.Component {
  style() {
    return {
      color: CustomColors.white,
      fontWeight: "bold",
      fontFamily: FontFamily.archivoBlack,
      fontSize: "40px",
      textAlign: "left",
    };
  }

  content() {
    return (
      <Grid
        style={{ height: "100%" }}
        container
        direction="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <Grid item style={{ margin: "-0.5em 0px 0px" }}>
          <Typography style={this.style()}>
            <span data-aos="fade-left" data-aos-delay={delay(100)}>
              You Are Not{" "}
            </span>
            <GradientText
              animationDelay={delay(1000)}
              fontFamily={FontFamily.archivoBlack}
              text="Alone"
              colors={{
                start: CustomColors.lime,
                end: CustomColors.purple,
              }}
              size={40}
              isSpan={true}
            />
            <span>.</span>
          </Typography>
        </Grid>

        <Grid item style={{ margin: "-0.5em 0px 0px" }}>
          <Typography style={this.style()}>
            <span data-aos="fade-left" data-aos-delay={delay(200)}>
              People Are{" "}
            </span>
            <GradientText
              animationDelay={delay(1500)}
              fontFamily={FontFamily.archivoBlack}
              text="Talking"
              colors={{
                start: CustomColors.lime,
                end: CustomColors.purple,
              }}
              size={40}
              isSpan={true}
            />
            <span>.</span>
          </Typography>
        </Grid>

        <Grid item style={{ margin: "-0.5em 0px 0px" }}>
          <Typography style={this.style()}>
            <span data-aos="fade-left" data-aos-delay={delay(300)}>
              Speak Your{" "}
            </span>
            <GradientText
              animationDelay={delay(2000)}
              fontFamily={FontFamily.archivoBlack}
              text="Mind"
              colors={{
                start: CustomColors.lime,
                end: CustomColors.purple,
              }}
              size={40}
              isSpan={true}
            />
            <span>.</span>
          </Typography>
        </Grid>
      </Grid>
    );
  }

  render = () => (
    <Grid container direction="row" justifyContent="center">
      <div>{this.content()}</div>
    </Grid>
  );
}

class GetAttentionTitle extends React.Component {
  render = () => (
    <div
      style={{
        textAlign: "center",
        marginLeft: "70px",
        marginRight: "70px",
        marginBottom: "30px",
        marginTop: "30px",
      }}
    >
      <span data-aos="fade-right" data-aos-delay={delay(300)}>
        <GradientText
          fontFamily={FontFamily.archivoBlack}
          text="The only social media "
          colors={{
            start: CustomColors.purple,
            end: CustomColors.lime,
          }}
          size={40}
          isSpan={true}
        />
      </span>
      <span data-aos="fade-right" data-aos-delay={delay(600)}>
        <GradientText
          fontFamily={FontFamily.archivoBlack}
          text="where posts turn into conversations."
          colors={{
            start: CustomColors.white,
            end: CustomColors.white,
          }}
          size={40}
          isSpan={true}
        />
      </span>
    </div>
  );
}

class AboutAppDesc extends React.Component {
  text = (val) => (
    <div
      style={{
        marginLeft: "70px",
        marginRight: "70px",
      }}
    >
      <Typography
        style={{
          color: CustomColors.textLightGray,
          fontFamily: FontFamily.apple,
          fontWeight: "bold",
          letterSpacing: "0px",
          fontSize: "25px",
          textAlign: "center",
        }}
      >
        {val}
      </Typography>
    </div>
  );

  render = () => (
    <Grid container direction="row" justifyContent="center">
      <Grid container direction="column">
        <Grid item style={{ paddingTop: "25px" }}>
          <div data-aos="fade-right" data-aos-delay={delay(900)}>
            {this.text("Each post becomes a group chat.")}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
