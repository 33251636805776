import Title from "../Title";
import Paragraph from "../Paragraph";

export default function AboutProfilePrivacy() {
  return (
    <div>
      <Title text="What is the difference between profile privacy and profile post privacy?" />
      <Paragraph>
        The distinction between profile privacy and profile post privacy lies in
        the scope of control over access to content. Profile privacy pertains to
        all content on a specific profile wall, including profile posts,
        granting control over who can access all pieces of content across the
        entire profile. On the other hand, profile post privacy is more
        specific, allowing you to control access to individual profile posts.
        This means you can have a public profile but make certain posts private
        or exclusively accessible to a select group, providing a finer level of
        control over the visibility of your content.
      </Paragraph>
      <Title text="How do I make my profile private or public?" />
      <Paragraph>
        To manage the privacy setting of your profile, on your profile page,
        access the profile specific settings and utilize the toggle switch to
        set your profile as either private or public.
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
    </div>
  );
}
