import React, { Component } from "react";
import "./FadeSlideShow.css";
import CrossfadeImage from "react-crossfade-image";

const sleep = (time) => new Promise((res) => setTimeout(res, time));

export default class Fade extends Component {
  old = () => <div id="cf">{this.props.children}</div>;

  constructor(props) {
    super(props);

    this.state = {
      imageIndex: 0,
    };

    this.changeImage = this.changeImage.bind(this);
    this.changeImage2 = this.changeImage2.bind(this);
  }

  componentDidMount() {
    this.changeImage();
  }

  async changeImage2() {
    await sleep(this.props.delay ?? 5000);
    if (this.state.imageIndex === this.props.images.length - 1) {
      this.setState({ imageIndex: 0 });
    } else {
      this.setState({ imageIndex: this.state.imageIndex + 1 });
    }
    await this.changeImage();
  }

  async changeImage() {
    await sleep(this.props.delay ?? 5000);
    if (this.state.imageIndex === this.props.images.length - 1) {
      this.setState({ imageIndex: 0 });
    } else {
      this.setState({ imageIndex: this.state.imageIndex + 1 });
    }
    await this.changeImage2();
  }

  render() {
    return (
      <div style={{ width: this.props.width ?? "450px" }}>
        <CrossfadeImage
          src={this.props.images[this.state.imageIndex]}
          duration={this.props.duration ?? 1000}
          timingFunction={"ease-out"}
        />
      </div>
    );
  }
}
