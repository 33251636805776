export default class CustomColors {
  static schmickScaffoldBackground = "rgb(220, 220, 240)";
  static textLightGray = "rgba(200, 200, 200, 0.75)";
  static paragraphColor = "rgb(130, 130, 130)";
  static black = "rgb(0, 0, 0)";
  static superDarkNavy = "rgb(30, 30, 45)";
  static superDarkNavy2 = "rgb(20, 20, 35)";
  static navy = "rgb(42, 1, 52)";
  static purple = "rgb(156, 39, 176)";
  static white = "rgb(255, 255, 255)";
  static slightWhite = "rgb(240, 240, 240)";
  static slightBlueWhite = "rgb(230, 230, 250)";
  static orange = "rgb(255, 153, 0)";
  static lime = "rgb(198, 255, 0)";
  static cyan = "rgb(24, 255, 255)";
  static blue = "rgb(0, 198, 255)";
}
