import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ProfileTile from "../../Components/ProfileTile/ProfileTile";
import { getProfiles } from "../../rest/get-profiles";
import Box from "@mui/material/Box";
import CustomColors from "../../CustomColors";
import "./HorProfileList.css";

const HorProfileList = () => {
  const [profiles, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching profiles...");
        const result = await getProfiles();
        console.log(`Profiles ${result.length} fetched!`);
        setData(result);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  let profileCount = 0;

  return (
    <Box sx={{ overflowX: "auto", maxWidth: 550, marginBottom: "70px" }}>
      <List sx={{ display: "flex", overflowX: "auto", flexDirection: "row" }}>
        {!profiles ? (
          <p style={{ color: CustomColors.textLightGray, height: 150 }}>
            Loading...
          </p>
        ) : (
          profiles.map((profile) => {
            profileCount++;

            return (
              <ListItem
                key={profile.userId}
                // No Padding for the list item
                style={{ padding: "0px", margin: "7px" }}
              >
                <div
                  data-aos="fade-left"
                  data-aos-delay={1000 + profileCount * 200}
                >
                  <ProfileTile
                    username={profile?.handle}
                    name={profile?.name}
                    avatarUrl={profile?.profileImage?.imageUrl}
                  />
                </div>
              </ListItem>
            );
          })
        )}
      </List>
    </Box>
  );
};

export default HorProfileList;
