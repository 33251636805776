import CustomColors from "./CustomColors";

export default function Title(props) {
  console.assert(props.text !== undefined, "Title text must not be undefined.");
  return (
    <div>
      <h1
        style={{
          marginTop: "50px",
          marginBottom: "30px",
          color: CustomColors.black,
          fontWeight: "bold",
          fontSize: "50px",
        }}
      >
        {props.text}
      </h1>
    </div>
  );
}
