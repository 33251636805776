import Title from "../Title";
import Paragraph from "../Paragraph";
import CustomColors from "../CustomColors";

import Grid from "@mui/material/Grid";

import BlockFromMyPost from "./assets/BlockFromMyPost.mp4";

export default function AboutBlockUserFromPost() {
  return (
    <div>
      <Title text="How can I block anon users from my posts?" />
      <Paragraph>
        When you block a user from your post, that user can no longer chat
        within your post. If you need to block a user from your post, you can
        by:
        <ol>
          <li>
            Holding down the{" "}
            <span style={{ color: CustomColors.purple }}>chat bubble</span>{" "}
            posted by that user.
          </li>
          <li>
            Select <span style={{ color: CustomColors.purple }}>‘Block’</span>{" "}
            from the menu.
          </li>
          <li>
            Press the{" "}
            <span style={{ color: CustomColors.purple }}>‘Hold me Here’</span>{" "}
            button to block.
          </li>
        </ol>
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <video autoPlay loop>
            <source src={BlockFromMyPost} type="video/mp4"></source>
          </video>
        </Grid>
      </Grid>
    </div>
  );
}
