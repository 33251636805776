import Title from "../Title";
import Paragraph from "../Paragraph";
import CustomColors from "../CustomColors";
import Grid from "@mui/material/Grid";
import SchmickOfTheDay from "./assets/SchmickOfTheDay.png";

export default function AboutSchmickOfTheDay() {
  return (
    <div>
      <Title text="What is Schmick of the day?" />
      <Paragraph>
        The{" "}
        <span style={{ color: CustomColors.purple }}>Schmick of the Day</span>{" "}
        is the hottest post of the day in each city. For{" "}
        <span style={{ color: CustomColors.orange }}>24 hours</span>, a post is
        placed on a pedestal to be seen by every Schmick user in that city. Once
        the time is up, Schmick selects a new post that is{" "}
        <span style={{ color: "red" }}>trending</span>. Start Schmicking to see
        your post as the Schmick of the Day!
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item xs={10}>
          <img
            alt=""
            src={SchmickOfTheDay}
            style={{ height: "60%", width: "60%" }}
          ></img>
        </Grid>
      </Grid>
    </div>
  );
}
