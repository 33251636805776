import Title from "../Title";
import Paragraph from "../Paragraph";

export default function AboutAddProfiles() {
  return (
    <div>
      <Title text="How can I include more people in my profile posts?" />
      <Paragraph>
        To include more people in your profile posts, activate tagging on the
        post. Once tagging is enabled, you can tag the individuals you want to
        include. This action will prompt them to receive a message in their
        inbox, notifying them of their inclusion in the post. Also, tagging is
        only available in profile posts.
      </Paragraph>
      <Title text="Why cant I tag someone?" />
      <Paragraph>
        There are specific reasons why you may encounter difficulty tagging
        someone. Firstly, tagging is limited to individuals who are following
        you, serving as a preventive measure against spam tagging. Additionally,
        tagging may have been disabled either by yourself or the profile post
        creator.
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
    </div>
  );
}
