import React from "react";
import FontFamily from "./FontFamily";

const GradientText = ({
  text,
  colors,
  size,
  fontFamily,
  isSpan,
  weight,
  animationDelay,
  animationType,
  textAlign,
}) => {
  const gradientTextStyle = {
    backgroundSize: "100% 200%",
    // backgroundPosition: "0 0",
    backgroundPosition: "50% 75%",

    background: `linear-gradient(45deg, ${colors.start ?? "purple"}, ${
      colors.end ?? "orange"
    })`,

    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "rgba(255, 255, 255, 0)",
    MozBackgroundClip: "text",
    MozTextFillColor: "rgba(255, 255, 255, 0)",

    fontSize: size ?? "80px",
    fontFamily: fontFamily ?? FontFamily.apple, // Font stack
    textAlign: textAlign ?? "center",
    // bold
    fontWeight: weight ?? "bold",
  };

  if (isSpan) {
    return (
      <span
        data-aos={animationType ?? "fade"}
        data-aos-delay={animationDelay ?? 0}
        style={gradientTextStyle}
      >
        {text}
      </span>
    );
  } else {
    return (
      <div
        data-aos={animationType ?? "fade"}
        data-aos-delay={animationDelay ?? 0}
        style={gradientTextStyle}
      >
        {text}
      </div>
    );
  }
};

export default GradientText;
