import React from "react";
import "./DownloadButton.css";

const DownloadButton = ({ text }) => {
  return (
    <div data-aos="zoom-in-up">
      <a href="https://www.schmick.app/join.html" class="btn btn-hover-light">
        {text ?? "Get Schmick"}
      </a>
    </div>
  );
};

const DownloadButtonDark = ({ text }) => {
  return (
    <div data-aos="zoom-in-up">
      <a href="https://www.schmick.app/join.html" class="btn btn-hover-dark">
        {text ?? "Get Schmick"}
      </a>
    </div>
  );
};

export { DownloadButton, DownloadButtonDark };
