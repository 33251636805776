import Title from "../Title";
import Paragraph from "../Paragraph";

export default function AboutProfileFollowRequests() {
  return (
    <div>
      <Title text="How do follow requests work?" />
      <Paragraph>
        When a profile is private and you wish to follow it, a follow request
        must be sent. The owner of the private profile must accept your follow
        request for you to become a follower and gain access to their content.
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
    </div>
  );
}
