import React from "react";
import CustomColors from "../CustomColors.js";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import WebConstraint from "../Components/WebConstraint.js";
import { DownloadButtonDark } from "../Components/DownloadButton/DownloadButton.js";
import FadeSlideShow from "../Components/FadeSlideShow/FadeSlideShow.js";
import { MediaQuerySize } from "../util/MediaQuery.js";
import { Center } from "../Components/Center.js";
import GradientText from "../Components/GradientText.js";
import FontFamily from "../Components/FontFamily.js";
import { Parallax } from "react-scroll-parallax";

import schmickProfileLightImgSrc from "./assets/schmick-profile-light-v2.png";
import schmickCreateLightImgSrc from "./assets/schmick-create-light-v2.png";
import schmickFullAnonChatLightImgSrc from "./assets/schmick-full-anon-chat-light-v2.png";

const delay = (val) => 300 + val;
const _mq = new MediaQuerySize(String(550 * 2) + "px");

export default class SocialSection extends React.Component {
  sectionHeight = () => "1080px";

  desc = (isMob) => (
    <Center>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems={isMob ? "center" : "flex-start"}
      >
        <Grid item>
          <SocialHeader isCenter={isMob} />
        </Grid>
        <Grid item style={{ marginTop: "70px" }}>
          <SocialDesc isCenter={isMob} />
        </Grid>
        <Grid item style={{ marginTop: "50px" }}>
          <DownloadButtonDark text="I want it" />
        </Grid>
      </Grid>
    </Center>
  );

  desktop = () => (
    // <ShadeFaderTop fadeEndColor="rgba(21,0,46,1)">
    <div
      style={{
        zIndex: 1,
        background:
          "linear-gradient(to bottom, #FFF0E1, white, white,white, #FFF0E1)",
        height: this.sectionHeight(),
      }}
    >
      <WebConstraint>
        <div style={{ position: "relative" }}>
          <Grid
            style={{ height: this.sectionHeight() }}
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item>
              <SchmickAppImg />
            </Grid>
            <Grid item>{this.desc()}</Grid>
          </Grid>
        </div>
      </WebConstraint>
    </div>
    // </ShadeFaderTop>
  );

  mobile = () => (
    <div
      style={{
        backgroundColor: CustomColors.orange,
        height: "1750px",
        paddingTop: "200px",
      }}
    >
      <Grid container direction="column">
        <Grid item>{this.desc(true)}</Grid>
        <Grid item>
          <div style={{ paddingTop: "310px" }}>
            <SchmickAppImg />
          </div>
        </Grid>
      </Grid>
    </div>
  );

  render = () => _mq.build({ desktop: this.desktop(), mobile: this.mobile() });
}

const SchmickAppImg = () => {
  return (
    // <div data-aos-duration="1000" data-aos="zoom-in">
    <Parallax speed={-30}>
      <Grid container direction="row" justifyContent="center">
        <FadeSlideShow
          width="450px"
          images={[
            schmickProfileLightImgSrc,
            schmickFullAnonChatLightImgSrc,
            schmickCreateLightImgSrc,
          ]}
        />
      </Grid>
    </Parallax>
    // </div>
  );
};

class SocialDesc extends React.Component {
  render = () => (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{
          width: "400px",
          textAlign: this.props.isCenter ? "center" : "none",
        }}
      >
        <Grid item data-aos="fade" data-aos-delay={delay(1700)}>
          <Typography
            style={{
              fontWeight: "bold",
              fontFamily: FontFamily.apple,
              color: "rgb(30, 30, 45, 0.8)",
              fontSize: "20px",
            }}
          >
            Be yourself, openly or anonymously, in a space where authentic,
            stress-free conversations thrive.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

class SocialHeader extends React.Component {
  style = (color) => ({
    color: color ?? CustomColors.black,
    fontWeight: "bold",
    fontFamily: FontFamily.archivoBlack,
    fontSize: "50px",
    textAlign: this.props.isCenter ? "center" : "left",
  });

  marg = () => ({ marginTop: "-1em" });

  content = () => (
    <Grid
      style={{ height: "100%" }}
      container
      direction="column"
      alignItems={this.props.isCenter ? "center" : "flex-start"}
      justifyContent="center"
    >
      <Grid item style={this.marg()}>
        <Typography
          data-aos="fade-up"
          data-aos-delay={delay(300)}
          style={this.style()}
        >
          Be Yourself.
        </Typography>
      </Grid>

      <Grid item style={this.marg()}>
        <div data-aos="fade-up" data-aos-delay={delay(600)}>
          <GradientText
            fontFamily={FontFamily.archivoBlack}
            text="Be Social."
            colors={{
              start: "purple",
              end: "rgba(255, 0, 0, 0.8)",
            }}
            size={50}
            isSpan={true}
          />
        </div>
      </Grid>

      <Grid item style={this.marg()}>
        <Typography
          data-aos="fade-up"
          data-aos-delay={delay(900)}
          style={this.style()}
        >
          Lose the Stress.
        </Typography>
      </Grid>

      <Grid item style={this.marg()}>
        <div data-aos="fade-up" data-aos-delay={delay(1200)}>
          <GradientText
            fontFamily={"Archivo Black"}
            text="Keep the Social."
            colors={{
              start: "purple",
              end: "rgba(255, 0, 0, 0.8)",
            }}
            size={50}
            isSpan={true}
          />
        </div>
      </Grid>
    </Grid>
  );

  render = () => (
    <Grid container direction="row">
      <Grid item>{this.content()}</Grid>
    </Grid>
  );
}
