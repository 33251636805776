import Title from "../Title";
import Paragraph from "../Paragraph";

export default function AboutProfileUnblock() {
  return (
    <div>
      <Title text="How do I unblock a profile?" />
      <Paragraph>
        To unblock a profile, follow these steps: In your profile settings,
        locate the blocked profile list. Tap on the specific profile you wish to
        unblock, which will take you to the profile's page. In the top right
        corner, access the vertical dots, where you will find the "unblock
        profile" option. This process allows you to easily reverse the blocking
        action and restore interactions with the unblocked profile.
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
    </div>
  );
}
