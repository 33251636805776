import React from "react";
import { useMediaQuery } from "react-responsive";

export class MediaQueryType {
  static isDesktopOrLaptop = "(min-device-width: 1224px)";
  static isBigScreen = "(min-device-width: 1824px)";
  static isMobile = "(max-width: 900px)";
  static isTabletOrMobile = "(max-width: 1224px)";
  static isTabletOrMobileDevice = "(max-device-width: 1224px)";
  static isPortrait = "(orientation: portrait)";
  static isRetina = "(min-resolution: 2dppx)";
}

export const ShowOn = (props) => {
  const isSizeExist = props?.width !== undefined ? true : false;
  const isMobile = useMediaQuery({
    query: isSizeExist
      ? `(max-width: ${props.width})`
      : MediaQueryType.isMobile,
  });
  return <>{isMobile ? props.mobile ?? <div /> : props.desktop ?? <div />}</>;
};

export class MediaQuerySize {
  constructor(size) {
    this.size = size ?? MediaQueryType.isMobile;
  }

  build = (props) => (
    <ShowOn desktop={props.desktop} mobile={props.mobile} width={this.size} />
  );
}
