import Title from "../Title";
import Paragraph from "../Paragraph";

export default function AboutProfileWallPosting() {
  return (
    <div>
      <Title text="How do I post on someone else's profile wall?" />
      <Paragraph>
        To post on someone else's profile wall, navigate to their profile page.
        Once there, click on the "say something" button, which will direct you
        to the post creation page.
      </Paragraph>
      <Title text="Why is my post not viewable on their wall?" />
      <Paragraph>
        The wall owner controls whether posts are being displayed publicly or
        privately.
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
    </div>
  );
}
