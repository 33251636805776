import Title from "../Title";
import Paragraph from "../Paragraph";

export default function AboutProfileBlock() {
  return (
    <div>
      <Title text="How do I block a profile?" />
      <Paragraph>
        Blocking on profiles involves a straightforward process. By clicking on
        the vertical dots on the profile page you want to block, the blocking
        option is revealed. Once a profile is blocked, mutual following between
        the profiles is terminated. The blocked profile loses the ability to
        comment or post on your wall, and any direct interaction with that
        profile is restricted. Additionally, you won't be able to search for or
        directly engage with the blocked profile after the blocking action has
        been taken.
      </Paragraph>
      <div style={{ padding: "30px" }}></div>
    </div>
  );
}
